<template>
  <div class="terms-content-text">
    <div class="section-h2 subtitle">{{ $t('TermsOfService.Title_Subject') }}</div>
    <div class="content-section">
      <div class="content-desc">
        {{ $t('TermsOfService.Desc_Subject') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section1') }}</div>
      <div class="content-desc">
        {{ $t('TermsOfService.Desc_before_link_Section1') }}
        <router-link class="router-text" :to="{name: 'Privacy'}">
          {{ $t('Dashboard.SportsDataCharityService') }} {{ $t('Privacy.Title_Subject') }}
        </router-link> 
        {{ $t('TermsOfService.Desc_after_link_Section1') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section2') }}</div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section2') }}</li>
        <li>{{ $t('TermsOfService.Desc2_Section2') }}</li>
        <li>{{ $t('TermsOfService.Desc3_Section2') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section3') }}</div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section3') }}</li>
        <li>{{ $t('TermsOfService.Desc2_Section3') }}</li>
        <li>{{ $t('TermsOfService.Desc3_Section3') }}</li>
        <li>{{ $t('TermsOfService.Desc4_Section3') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section4') }}</div>
      <div class="content-desc">
        {{ $t('TermsOfService.Desc_Section4') }}
      </div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section4') }}</li>
        <li>{{ $t('TermsOfService.Desc2_Section4') }}</li>
        <li>{{ $t('TermsOfService.Desc3_Section4') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section5') }}</div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section5') }}
          <div>{{ $t('TermsOfService.Desc1_1_Section5') }}</div>
          <div>{{ $t('TermsOfService.Desc1_2_Section5') }}</div>
          <div>{{ $t('TermsOfService.Desc1_3_Section5') }}</div>   
        </li>
        <li>{{ $t('TermsOfService.Desc2_Section5') }}</li>
        <li>{{ $t('TermsOfService.Desc3_Section5') }}</li>
        <li>{{ $t('TermsOfService.Desc4_Section5') }}</li>
        <li>{{ $t('TermsOfService.Desc5_Section5') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section6') }}</div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section6') }}</li>
        <li>{{ $t('TermsOfService.Desc2_Section6') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section7') }}</div>
      <ol>
        <li>{{ $t('TermsOfService.Desc1_Section7') }}</li>
        <li>{{ $t('TermsOfService.Desc2_Section7') }}</li>
        <li>{{ $t('TermsOfService.Desc3_Section7') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section8') }}</div>
      <div class="content-desc"> {{ $t('TermsOfService.Desc1_Section8') }}</div>
      <div class="content-desc">
        {{ $t('TermsOfService.Desc2_Section8') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('TermsOfService.Title_Section9') }}</div>
      <div class="content-desc">
        {{ $t('TermsOfService.Desc_Section9') }}
      </div>
    </div>
  </div>
</template>

<script>
import { DownloadFile } from '@/Mixins'
export default {
  name: 'TermsOfService',
  mixins: [DownloadFile]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.terms-content-text{
  word-break: normal;
  .subtitle{
    padding-left: 10px;
  }
  color: $text;
  h2{
    text-align: center;
  }
  .content-section{
    padding: 0.625rem;
    width: 100%;
    .router-text{
      color: $primary;
      font-weight: bold;
    }
      
    .content-desc{
      line-height: 1.5;
      padding: 0.625rem 0;
      
    }
    .content-title{
      font-weight: bold;
    }
    li{
      line-height: 1.5;
    }
  }
}
</style>
